import React from "react"

import Icon from "../../images/assets/kaigo-logo.svg"
import { useIntl, changeLocale, Link } from "gatsby-plugin-intl"

const Footer = () => {
  const intl = useIntl()
  let langEnStyle = "link";
  let langNlStyle = "link";
  if(typeof window !== `undefined` && window.location.href.includes("/en/")) langEnStyle += " link-active";
  if(typeof window !== `undefined` && window.location.href.includes("/nl/")) langNlStyle += " link-active";
  return (
    <footer>
      <div className="hr" />
      <h3 className="header" style={{margin: "10px"}}>Dassport</h3>
      <div>
        <div>
          <h5>{intl.formatMessage({ id: "footer.body.contact" })}</h5>
          <p>{intl.formatMessage({ id: "footer.body.questions1" })}</p>
          <p>{intl.formatMessage({ id: "footer.body.questions2" })}<span><a className="link link-active" href="mailto:dassport@kaigo.io">dassport@kaigo.io</a></span></p>
          <br/>
          <h5>{intl.formatMessage({ id: "footer.body.status" })}</h5>
          <p><span><a className="link link-active" target="__blank" href="https://status.dassport.nl">status.dassport.nl</a></span></p>
        </div>
        <div className="lang-select">
          <h5>{intl.formatMessage({ id: "footer.body.language" })}</h5>
          <a className={langEnStyle} onClick={() => changeLocale("en")}>{intl.formatMessage({ id: "footer.body.english" })}</a>
          <a className={langNlStyle} onClick={() => changeLocale("nl")}>{intl.formatMessage({ id: "footer.body.dutch" })}</a>
        </div>
      </div>
      <div>
        <div>
          <div style={{ fontSize: "16px", marginTop: "10px" }}><Link className="link link-active" to="/terms">{intl.formatMessage({ id: "footer.body.terms" })}</Link></div>
          <div style={{ fontSize: "16px", marginTop: "10px" }}><Link className="link link-active" to="/privacy">{intl.formatMessage({ id: "footer.body.privacy" })}</Link></div>
        </div>
        <div>
          <Icon className="footer-logo" />
          &copy; {new Date().getFullYear()}, 
          <a style={{marginLeft: "10px"}} className="link link-active" href="https://kaigo.io">Kaigo IT Solutions</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import animate from "animejs"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useIntl, Link } from "gatsby-plugin-intl"
import { connect } from "react-redux"
import { searchBarToggle } from "@kaigo/gatsby-theme-docs/src/actions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Icon from "../../images/assets/logo.svg"

const Header = ({ siteTitle, search, searchBarToggle }) => {
  const [y, setY] = useState(0)
  const [open, setOpen] = useState(false)
  const navRef = React.createRef()
  const barOneRef = React.createRef()
  const barTwoRef = React.createRef()
  const barThreeRef = React.createRef()

  const intl = useIntl()

  const handleScroll = () => {
    if(typeof window !== `undefined`) {
      setY(window.scrollY)
    }
  }

  useEffect(() => {
    if(typeof window !== `undefined`) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    } else {
      return
    }
  })

  useEffect(() => {
    animate({
      targets: navRef.current,
      opacity: "1",
      delay: "300"
    })

    if (open) {
      animate({
        targets: barOneRef.current,
        points: [{
          value: ['0.36 4.05 20.36 4.05 40.36 4.05 60.36 4.05 80.36 4.05 100.36 4.05 120.36 4.05'],
          easing: "linear",
          duration: "200"
        }],
        rotateZ: [{ value: "45deg", delay: 200 }]
      })
      animate({
        targets: barTwoRef.current,
        points: [{
          value: ['0.36 36.05 20.36 36.05 40.36 36.05 60.36 36.05 80.36 36.05 100.36 36.05 120.36 36.05'],
          easing: "linear",
          duration: "200"
        }],
        opacity: [{ value: "0", delay: 200 }]
      })
      animate({
        targets: barThreeRef.current,
        points: [{
          value: ['0.36 68.05 20.36 68.05 40.36 68.05 60.36 68.05 80.36 68.05 100.36 68.05 120.36 68.05'],
          easing: "linear",
          duration: "200"
        }],
        rotateZ: [{ value: "-45deg", delay: 200 }],
        translateX: [{ value: "-50%", delay: 200 }],
        translateY: [{ value: "-50%", delay: 200 }]
      })
    } else {
      animate({
        targets: barOneRef.current,
        points: [{
          value: ['0.36 6.55 20.36 1.55 40.36 6.55 60.36 1.55 80.36 6.55 100.36 1.55 120.36 6.55'],
          easing: "linear",
          duration: "200"
        }],
        rotateZ: [{ value: "0deg" }]
      })
      animate({
        targets: barTwoRef.current,
        points: [{
          value: ['0.36 38.55 20.36 33.55 40.36 38.55 60.36 33.55 80.36 38.55 100.36 33.55 120.36 38.55'],
          easing: "linear",
          duration: "200"
        }],
        opacity: [{ value: "0.7" }]
      })
      animate({
        targets: barThreeRef.current,
        points: [{
          value: ['0.36 70.55 20.36 65.55 40.36 70.55 60.36 65.55 80.36 70.55 100.36 65.55 120.36 70.55'],
          easing: "linear",
          duration: "200"
        }],
        rotateZ: [{ value: "0deg" }],
        translateX: [{ value: "0%" }],
        translateY: [{ value: "0%" }]
      })
    }
  }, [open])

  return (
    <>
      <header style={{ boxShadow: `0px 4px 6px -1px rgba(0,0,0,${y < 200 ? `${y / 200 * 0.2}` : "0.2"})`, paddingTop: `${y < 100 ? "40px" : "20px"}`, height: `${open ? "100vh" : (y < 100 ? "113px" : "93px")}` }}>
        <div>
          <Link style={{display: "flex", justifyContent: "center"}} to="/">
            <Icon className="header-logo" />
            <h3 className="header" style={{margin: "0px", lineHeight: "50px", marginLeft: "10px", fontSize: "18px"}}>Dassport</h3>
          </Link>

          <svg className="nav-open" onClick={() => setOpen(!open)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.73 72.09" height="30px" width="30px"><title>nav</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polyline className="cls-1" style={{ fill: "none", stroke: "#191919", strokeWidth: "5px" }} ref={barOneRef} points="0.36 6.55 20.36 1.55 40.36 6.55 60.36 1.55 80.36 6.55 100.36 1.55 120.36 6.55" /><polyline className="cls-1" style={{ fill: "none", stroke: "#191919", strokeWidth: "5px" }} ref={barTwoRef} points="0.36 38.55 20.36 33.55 40.36 38.55 60.36 33.55 80.36 38.55 100.36 33.55 120.36 38.55" /><polyline className="cls-1" style={{ fill: "none", stroke: "#191919", strokeWidth: "5px" }} ref={barThreeRef} points="0.36 70.55 20.36 65.55 40.36 70.55 60.36 65.55 80.36 70.55 100.36 65.55 120.36 70.55" /></g></g></svg>
          {typeof window !== `undefined` && (window.location.pathname === "/en/" || window.location.pathname === "/nl/") && <div className="nav-buttons">
            <AnchorLink className="header" offset="93" href='#features-section'>{intl.formatMessage({ id: "nav.features" })}</AnchorLink>
            <AnchorLink className="header" offset="93" href='#pricing-section'>{intl.formatMessage({ id: "nav.pricing" })}</AnchorLink>
            <AnchorLink className="header" offset="93" href='#contact-form'>{intl.formatMessage({ id: "nav.contact" })}</AnchorLink>
            <Link className="header separate" offset="93" to='/docs'>{intl.formatMessage({ id: "nav.resources" })}</Link>
            <FontAwesomeIcon icon="search" style={{cursor: "pointer"}} onClick={() => searchBarToggle(true)} />
          </div>}
          {typeof window !== `undefined` && (window.location.pathname != "/en/" && window.location.pathname != "/nl/") && <div className="nav-buttons">
            <Link className="header" offset="93" to='/#features-section'>{intl.formatMessage({ id: "nav.features" })}</Link>
            <Link className="header" offset="93" to='/#pricing-section'>{intl.formatMessage({ id: "nav.pricing" })}</Link>
            <Link className="header" offset="93" to='/#contact-form'>{intl.formatMessage({ id: "nav.contact" })}</Link>
            <Link className="header separate" offset="93" to='/docs'>{intl.formatMessage({ id: "nav.resources" })}</Link>
            <FontAwesomeIcon icon="search" style={{cursor: "pointer"}} onClick={() => searchBarToggle(true)} />
          </div>}
        </div>
        {typeof window !== `undefined` && open && (window.location.pathname === "/en/" || window.location.pathname === "/nl/") && (
          <div className="nav" ref={navRef}>
            <AnchorLink className="header" onClick={() => setOpen(false)} offset="93" href='#features-section'>{intl.formatMessage({ id: "nav.features" })}</AnchorLink>
            <AnchorLink className="header" onClick={() => setOpen(false)} offset="93" href='#pricing-section'>{intl.formatMessage({ id: "nav.pricing" })}</AnchorLink>
            <AnchorLink className="header" onClick={() => setOpen(false)} offset="93" href='#contact-form'>{intl.formatMessage({ id: "nav.contact" })}</AnchorLink>
            <Link className="header separate" onClick={() => setOpen(false)} offset="93" to='/docs'>{intl.formatMessage({ id: "nav.resources" })}</Link>
            <FontAwesomeIcon icon="search" style={{cursor: "pointer"}} onClick={() => searchBarToggle(true)} />
          </div>
        )}
        {typeof window !== `undefined` && open && (window.location.pathname != "/en/" && window.location.pathname != "/nl/") && (
          <div className="nav" ref={navRef}>
            <Link className="header" onClick={() => setOpen(false)} offset="93" to='/#features-section'>{intl.formatMessage({ id: "nav.features" })}</Link>
            <Link className="header" onClick={() => setOpen(false)} offset="93" to='/#pricing-section'>{intl.formatMessage({ id: "nav.pricing" })}</Link>
            <Link className="header" onClick={() => setOpen(false)} offset="93" to='/#contact-form'>{intl.formatMessage({ id: "nav.contact" })}</Link>
            <Link className="header separate" onClick={() => setOpen(false)} offset="93" to='/docs'>{intl.formatMessage({ id: "nav.resources" })}</Link>
            <FontAwesomeIcon icon="search" style={{cursor: "pointer"}} onClick={() => searchBarToggle(true)} />
          </div>
        )}
      </header>
      <div className="header-placeholder" />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
}

function mapStateToProps(state) {
  return {
    search: state.searchBar
  };
}

export default connect(mapStateToProps, { searchBarToggle })(Header)
